<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Раздел {{ titlePrefix }} поддержки</subpage-top-bar>
        
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="ready"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col :cols="4" class="fill-height overflow-auto d-flex flex-column flex-nowrap justify-start">

                    <div
                        class="d-flex flex-column justify-start align-start fill-height mb-5 overflow-y-auto"
                    >
                        <ticket-list-item
                            v-for="item in items"
                            :key="item.key"
                            :item="item"
                            class="pa-2 c-pointer ticket-item w100"
                            :class="{active: selectedItem && typeof selectedItem === 'object' && item.id === selectedItem.id}"
                            @click.native="fetchTicketDetails(item.id)"
                        />
                    </div>
                    <div class="d-flex flex-row justify-start align-center flex-grow-0">
                        <v-btn
                            dark
                            block
                            color="purple darken-3"
                            class="mb-2"
                            @click.prevent.stop="fetch"
                        >
                            <v-icon class="mr-1">mdi-refresh</v-icon>
                            Обновить список
                        </v-btn>
                    </div>


                </v-col>
                <v-divider vertical />
                <v-col :cols="8" class="fill-height overflow-auto">
                    <div v-if="loading" class="fill-height w-100 d-flex flex-column flex-nowrap justify-center align-center">
                        <div class="text-h5">Загрузка...</div>
                    </div>
                    <div v-else-if="!selectedItem" class="fill-height w-100 d-flex flex-column flex-nowrap justify-center align-center">
                        <div class="text-h5">Выберите элемент в списке слева</div>
                    </div>
                    <ticket-viewer
                        v-else
                        :item="selectedItem"
                        @onUpdate="onTicketUpdateHandler"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import TicketListItem from '@/components/support/TicketListItem'
import TicketViewer from '@/components/support/TicketViewer'
import { mapGetters } from 'vuex'
import SubpageTopBar from '@/components/template/SubpageTopBar'

export default {
    name: "Support",
    components: {
        TicketListItem,
        TicketViewer,
        SubpageTopBar
    },
    data () {
        return {
            ready: false,
            loading: false,
            items: [],
            selectedItem: null
        };
    },
    computed: {
        ...mapGetters("user", ["allowSupport"]),
        supportCategoryID () {
            return this.$route.query.cat_id
        },
        titlePrefix () {
            if (!this.supportCategoryID) { return '' }
            switch (this.supportCategoryID) {
                case '1':
                    return 'технической';
                case '2':
                    return 'методической';
                default:
                    return '';
            }
        }
    },
    methods: {
        async fetch () {
            if (this.loading || !this.allowSupport) { return false; }
            this.selectedItem = null;
            this.serverErrors = null;
            this.loading = true;
            const filter = {};
            if (this.supportCategoryID) { filter.category_id = this.supportCategoryID }
            const { success, data, error } = await this.$store.dispatch('support/ticketList', { filter });

            if (success) {
                this.items = data.items;
            } else {
                this.serverErrors = error;
            }
            this.loading = false;
            return true;
        },
        reset () {
            this.fetch();
        },
        async fetchTicketDetails (id) {
            if (this.loading) { return false; }

            this.selectedItem = null;
            this.serverErrors = null;
            this.loading = true;

            const { success, data, error } = await this.$store.dispatch('support/ticketDetails', { id });

            if (success) {
                this.selectedItem = data.item;
                // Обновление списка новыми загруженными данными
                this.items = this.items.map(_item => {
                    if (_item.id === this.selectedItem.id) {
                        _item = _.cloneDeep(this.selectedItem);
                    }
                    return _item;
                })
            } else {
                this.serverErrors = error;
            }
            this.loading = false;
            return true;
        },
        onTicketUpdateHandler (updatedItem) {
            this.items = this.items.map(item => {
                if (updatedItem.id === item.id) {
                    item = _.cloneDeep(updatedItem);
                    this.selectedItem = item;
                }
                return item;
            })
        }
    },
    async created () {
        // Fetch data 
        await this.fetch();
        // Ready
        this.ready = true;
    }
};
</script>

<style lang="scss" scoped>
.c-pointer {
    cursor: pointer;
}
.ticket-item {
    border-radius: 4px;
    &.active {
        background-color: #f4f4f4;
    }
}
.w100 {
    width: 100%;
}
</style>

<style lang="scss">
.page-support {
    height: 100vh;
}
</style>