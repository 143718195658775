<template>
  <div class="fill-height">
    <div class="text-h5">Детали обращения № <strong>{{ item.number }}</strong></div>

    <div class="form-group">
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          <v-select
            v-model="currentStatus"
            :items="statuses"
            label="Статус обращения"
            dense
            @input="updateStatusHandler"
          ></v-select>
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">ФИО</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          {{ item.name || '-' }}
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">IP адрес</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          {{ item.ip || '-' }}
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">Страна / Город</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          {{ item.country || '-' }} / {{ item.city || '-' }}
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">Указанный ЦОК</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          <template v-if="boundedCourse">
            <span>
              {{ boundedCourse.courseName }}
            </span>
            <v-btn
              v-if="boundedCourse.shortCustomUrl || boundedCourse.url"
              :href="boundedCourse.shortCustomUrl || boundedCourse.url"
              target="_blank"
              icon
              class="ml-2"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span v-else> - </span>
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">Сообщение от клиента</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          <a
            v-if="item.email"
            :href="'mailto:'+item.email"
          >
            {{ item.email }}
          </a>
          <span v-else >-</span>
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">Сообщение от клиента</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center bordered rounded pa-2">
          {{ item.message || '-' }}
        </div>
    </div>

    <div class="form-group">
				<label class="v-label v-label--active v-label--dense theme--light">Прикрепленный файл</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center">
          <template v-if="item.file && typeof item.file === 'string'">
            <span>
              {{ item.file }}
            </span>
            <v-btn
              :href="item.file"
              target="_blank"
              icon
              class="ml-2"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span v-else> - </span>
        </div>
    </div>

    <div
      v-if="item.solution"
      class="form-group"
    >
				<label class="v-label v-label--active v-label--dense theme--light">Решение обращения</label>
				<div class="d-flex flex-row flex-wrap justify-between align-center bordered rounded border__green pa-2">
          {{ item.solution }}
        </div>
    </div>

    <div class="form-group pb-5">
				<label class="v-label v-label--active v-label--dense theme--light text-x1_5">Создано: {{ createdAt }} | Обновлено: {{ updatedAt }}</label>
    </div>

    <v-alert
      v-model="formError.isShowing"
      type="error"
      dismissible
    >
      {{ formError.text }}
    </v-alert>

    <v-dialog v-model="showResultDialog" persistent max-width="480">
      <v-card>
        <v-card-title class="text-h5">
          Введите итоговое решение текущего обращения
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="resultInfo"
            name="resultInfo" 
            label="Ваше решение"
            flat
        ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            dark 
            color="error darken-3" 
            class="mx-auto"
            @click="declineResultDialogHandler"
          >
            Отмена
          </v-btn>
          <v-btn 
            dark 
            color="purple darken-3" 
            class="mx-auto"
            @click="acceptResultDialogHandler"
          >
            Завершить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TicketViewer',
  props: {
    item: {
      type: Object,
      required: true,
      validator: obj => obj.number && obj.status
    }
  },
  data () {
    return {
      currentStatus: null,
      loading: false,
      formError: null,
      showResultDialog: false,
      resultInfo: null,
      dialogPromiseResolver: null
    }
  },
  created () {
    this.resetError();
  },
  computed: {
    ...mapGetters("user", ["allowSupport"]),
    statuses () {
      return Object.keys(this.$const.eduCourse.statuses).map(key => {
        const item = this.$const.eduCourse.statuses[key]
        return {
          value: key,
          text: item.label,
          disabled: !this.isStatusActive(key)
        }
      })
    },
    createdAt () {
      return this.getFormatedDateAndTime(this.item.created_at);
    },
    updatedAt () {
      return this.getFormatedDateAndTime(this.item.updated_at);
    },
    eduCourses () {
      return this.$store.state.app.eduCoursesShort;
    },
    boundedCourse () {
      return this.eduCourses.find(c => parseInt(c.id) === parseInt(this.item.course_id))
    }
  },
  watch: {
    item () {
      this.currentStatus = this.item.status;
    }
  },
  mounted () {
    this.currentStatus = this.item.status;

    this.$store.dispatch('app/fetchFast', {filter: ['AND', ['=', 'courseTypeId', 0], ['=', 'synced', 1]]});
  },
  methods: {
    isStatusActive (status) {
      if (status === 'solved' && this.currentStatus === 'submitted') return false;
      if (status === 'submitted' && this.currentStatus !== status) return false;
      if (status === 'at_work' && this.currentStatus === 'solved') return false;
      return true;
    },
    resetError () {
      this.formError = {
          text: '',
          isShowing: false
        }
    },
    setError (text) {
      this.formError.text = text;
      this.formError.isShowing = true;
    },
    getFormatedDateAndTime (timestamp) {
      const _timestamp = timestamp * 1000,
            date = new Date(_timestamp);

      return `${this.prepedZero(date.getDate())}.${this.prepedZero(date.getMonth())}.${this.prepedZero(date.getFullYear())} ${this.prepedZero(date.getHours())}:${this.prepedZero(date.getMinutes())}`;
    },
    prepedZero (num) {
      let _num = num;
      if (parseInt(_num) > 9) { return _num; }
      if (typeof _num !== 'string') { _num = `${_num}`; }
      return `0${_num}`;
    },
    async updateTicket (payload) {
      if (this.loading || !this.allowSupport) { return false; }
      if (!payload) { throw new Error('Can\'t update ticket with no any data.'); }
      this.loading = true;
      this.resetError();

      const { error } = await this.$store.dispatch('support/updateTicket', { id: this.item.id, model: payload });

      if (error) {
        console.error(error);
        this.setError('При обновлении статуса возникла ошибка. Обратитесь за помощью к техническому специалисту.');
      }

      this.loading = false;
      this.$emit(
        'onUpdate',
        {
          ...this.item,
          ...payload,
          updated_at: parseInt(Date.now() / 1000).toString()
        }
      );
      return true;
    },
    async updateStatusHandler () {
      if (this.currentStatus === 'solved') {
        this.showResultDialog = true
        // Ожидание закрытия диалогового окна
        await new Promise (resolve => { this.dialogPromiseResolver = resolve; })

        this.showResultDialog = false;

        if (this.resultInfo?.length) {
          this.updateTicket({
            status: this.currentStatus,
            solution: this.resultInfo
          });
        } else {
          const status = this.statuses.find(s => s.value === this.currentStatus)?.text || 'Undefined';
          this.setError(`Невозможно установить статус "${status}" без указания решения обращения`);
          // Возвращаем прежний статус в форме
          this.currentStatus = this.statuses.find(s => s.value === this.item.status);
        }
      } else {
        this.updateTicket({status: this.currentStatus});
      }
    },
    acceptResultDialogHandler () {
      this.dialogPromiseResolver?.();
      this.dialogPromiseResolver = null;
    },
    declineResultDialogHandler () {
      this.dialogPromiseResolver?.();
      this.dialogPromiseResolver = null;
      this.$nextTick(this.resetError);
    }
  }
}
</script>

<style scoped lang="scss">
.form-group{
	$my: 16px;
	margin-top: $my;
	margin-bottom: $my;
}
.text-x1_5 {
  font-size: 1.5em;
}
.bordered {
  border: 1px solid #ccc;
}
.border__green {
  border-color: green;
}
</style>