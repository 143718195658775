<template>
  <div class="d-flex flex-row justify-space-between">
    <div class="d-flex flex-column">
      <span class="d-flex flex-row align-center">
        <v-icon class="mr-2">mdi-ticket-confirmation-outline</v-icon>
        <span class="h1 font-weight-black">{{ item.number }}</span>
      </span>
      <v-chip class="mt-1" :color="statusInfo.color">
        {{ statusInfo.label }}
      </v-chip>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-end align-center text-right flex-grow-1">
        Создано:&nbsp;<i>{{ createdAt }}</i>
      </div>
      <div class="d-flex flex-row justify-end align-center text-right flex-grow-1">
        Обновлено:&nbsp;<i>{{ updatedAt }}</i>
      </div>
    </div>

    <!-- <div class="d-flex flex-row justify-space-between align-center flex-grow-1">
      
      
    </div> -->
    
  </div>
</template>

<script>

export default {
  name: 'TicketListItem',
  props: {
    item: {
      type: Object,
      required: true,
      validator: obj => obj.number && obj.status
    }
  },
  computed: {
    statusInfo () {
      return this.getStatusInfo(this.item.status);
    },
    createdAt () {
      return this.getFormatedDateAndTime(this.item.created_at);
    },
    updatedAt () {
      return this.getFormatedDateAndTime(this.item.updated_at);
    }
  },
  methods: {
    getStatusInfo (statusSlug) {
      const status = this.$const.eduCourse.statuses[statusSlug],
            undefinedStatus = { label: 'Неизвестно', color: 'default' };

      return status || undefinedStatus;
    },
    getFormatedDateAndTime (timestamp) {
      const _timestamp = timestamp * 1000,
            date = new Date(_timestamp);

      return `${this.prepedZero(date.getDate())}.${this.prepedZero(date.getMonth())}.${this.prepedZero(date.getFullYear())} ${this.prepedZero(date.getHours())}:${this.prepedZero(date.getMinutes())}`;
    },
    prepedZero (num) {
      let _num = num;
      if (parseInt(_num) > 9) { return _num; }
      if (typeof _num !== 'string') { _num = `${_num}`; }
      return `0${_num}`;
    }
  }
}
</script>